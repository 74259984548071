/* Bestsellers.css */
.bestsellers-page {
    padding: 20px;
  }
  
  .bestsellers-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .bestsellers-table th, .bestsellers-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .bestsellers-table img {
    max-width: 100px;
    max-height: 100px;
  }
  