/* CustomerSupport.css */
.customer-support {
    padding: 20px;
  }
  
  .customer-support h1 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .customer-support h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .contact-messages-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .contact-messages-table th, .contact-messages-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .contact-messages-table th {
    background-color: #f4f4f4;
  }
  
  .contact-messages-table a {
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-messages-table a:hover {
    text-decoration: underline;
  }
  