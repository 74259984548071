/* General Table Styling */
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

th, td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
}

th {
    background-color: #f4f4f4;
    color: #333;
    font-weight: bold;
}

td {
    background-color: #fff;
    color: #555;
}

/* Alternate Row Colors */
tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

tbody tr:hover {
    background-color: #f1f1f1;
}

/* Button Styling */
button {
    background-color: #000;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #000;
}

/* Loading & No Data Messages */
p {
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
    color: #555;
}

tr td[colspan="4"], tr td[colspan="5"] {
    text-align: center;
    font-style: italic;
    color: #777;
}

/* Heading Styles */
h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

/* Container Styling */
.transactions-page {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fafafa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Back Button */
button.back-to-overview {
    background-color: #ff6347;
    margin-bottom: 20px;
}

button.back-to-overview:hover {
    background-color: #e5533f;
}

/* Toast Notification Styles */
.Toastify__toast-container {
    z-index: 9999;
}
