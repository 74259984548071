/* AdminPanel.css */

.admin-panel {
  max-width: 100vw;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.admin-panel h2 {
  margin-bottom: 20px;
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns in a row */
  gap: 15px;
  justify-items: center; /* Center items within each grid cell */
}

.panel-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  width: 250px;
  text-align: center;
  transition: background-color 0.3s;
}

.panel-button:hover {
  background-color: #333;
}

/* Responsive design for smaller screens */
@media (max-width: 600px) {
  .button-grid {
      grid-template-columns: 1fr; /* Switch to a single column on smaller screens */
      gap: 10px;
  }
  
  .panel-button {
      width: 100%;
      padding: 10px;
  }
}
