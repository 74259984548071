.admin-orders-page {
    padding: 20px;
}

.admin-orders-table {
    width: 100%;
    border-collapse: collapse;
}

.admin-orders-table th, .admin-orders-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
}

.admin-orders-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}




/* Basic Styles for the Admin Orders Page */
.admin-orders-page {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Search Bar */
  .search-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .search-input {
    padding: 8px 15px;
    font-size: 16px;
    width: 200px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
  }
  
  .search-input:focus {
    border-color: #000;
  }
  
  /* Filter Bar */
  .filter-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .filter-select {
    padding: 8px 15px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    width: 220px;
  }
  
  .filter-select:focus {
    border-color: #4e90d2;
  }
  
  /* Orders Table */
  .admin-orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .admin-orders-table th, .admin-orders-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .admin-orders-table th {
    background-color: #f4f4f4;
    color: #555;
  }
  
  .admin-orders-table td {
    background-color: #fff;
  }
  
  .admin-orders-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .admin-orders-table a {
    color: #4e90d2;
    text-decoration: none;
  }
  
  .admin-orders-table a:hover {
    text-decoration: underline;
  }
  
  /* Pagination */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 8px 16px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    background-color: #f4f4f4;
    cursor: not-allowed;
  }
  
  .pagination button.active {
    background-color: #000;
    color: white;
  }
  
  /* Modal Styles */
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 900px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow-y: auto;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;
  }

  /* Add this to make the modal scrollable */
.modal {
    max-width: 90%;
    max-height: 80%; /* Adjust this to suit your design */
    overflow-y: auto; /* This enables vertical scrolling */
    padding: 20px;
    background-color: white;
    border-radius: 10px;
  }
  
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  
  h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .order-details {
    font-size: 16px;
    color: #555;
  }
  
  .order-details p {
    margin: 5px 0;
  }
  
  .order-details h4 {
    margin-top: 20px;
    font-size: 18px;
    color: #333;
  }
  
  .order-details a {
    color: #4e90d2;
    text-decoration: none;
  }
  
  .order-details a:hover {
    text-decoration: underline;
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  button:hover {
    background-color: #000;
  }
  
  /* Responsive Design for smaller screens */
  @media (max-width: 768px) {
    .search-input, .filter-select {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .admin-orders-table {
      font-size: 14px;
    }
  
    .pagination button {
      font-size: 14px;
      padding: 6px 12px;
    }
  }
  