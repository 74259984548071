.designs-page {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .filter-container {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .filter-container label {
    margin-right: 10px;
  }
  
  .filter-container select {
    padding: 5px 10px;
  }
  
  .designs-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .designs-table th, .designs-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .designs-table th {
    background-color: #f4f4f4;
  }
  
  .designs-table img {
    width: 100px;
    height: auto;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pagination button {
    padding: 10px 15px;
    margin: 0 10px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .pagination button:disabled {
    background-color: #d6d6d6;
    cursor: not-allowed;
  }
  
  .pagination span {
    font-size: 16px;
  }
  